exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-sorry-jsx": () => import("./../../../src/pages/sorry.jsx" /* webpackChunkName: "component---src-pages-sorry-jsx" */),
  "component---src-pages-subscribe-jsx": () => import("./../../../src/pages/subscribe.jsx" /* webpackChunkName: "component---src-pages-subscribe-jsx" */),
  "component---src-templates-video-category-archive-jsx": () => import("./../../../src/templates/video-category-archive.jsx" /* webpackChunkName: "component---src-templates-video-category-archive-jsx" */),
  "component---src-templates-video-post-jsx": () => import("./../../../src/templates/video-post.jsx" /* webpackChunkName: "component---src-templates-video-post-jsx" */)
}

