import { navigate } from "gatsby"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? window.localStorage.getItem("gatsbyUser")
    : {}

const setUser = user =>{

  window.localStorage.setItem("gatsbyUser", user.webid.fnid);
  window.localStorage.setItem("gatsbyUser_is_school", user.is_school.data_is_school);
  window.localStorage.removeItem('gatsbyUserCheck');
  window.localStorage.removeItem('gatsbyUserRegionCheck');

}

export const handleLogin = ({ fnid, region }) => {

  fetch('https://app.firstnews.co.uk/api/videos/'+fnid+'/'+region, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "xEOsW9cXDg3ktSg"  
    },
  })
  .then(response => {

    if (response.ok) {

      return response.json();

    }

    throw response;

  } )
  .then( data => { 

    // console.log('Login data', data);

    if( data.result === 'true' ) {
      if( data.is_freemium == 'true') {
        window.localStorage.setItem("gatsbyUser_is_freemium", 'yes');
      }
      setUser({
        webid: { fnid }
      })

      return data;

    }
    else {
      // console.log('not valid')
      window.localStorage.removeItem('gatsbyUser');
      window.localStorage.removeItem('gatsbyUserCheck');
      window.localStorage.removeItem('gatsbyUserRegionCheck');
      return false;
    }
  } );

  return false;

}

export const isLoggedIn = ( access_token ) => {

  const user = getUser()

  console.log( access_token );

  const isBrowser = typeof window !== "undefined"
  
  if (isBrowser) {
    const url = window.location;
    const access_token = new URLSearchParams(url.search).get("token");
    console.log('access token in auth', access_token);

    if(access_token === undefined) {
      return true;
    }

    if(!user.webid) {

      if(window.localStorage.getItem("gatsbyUser")) {

        return true;

      }
      else if(window.localStorage.getItem("gatsbyUserCheck")) {

        const fnid = window.localStorage.getItem("gatsbyUserCheck");
        const region = window.localStorage.getItem("gatsbyUserRegionCheck");

        handleLogin( fnid, region );
        
      }

      else {
        if( !access_token ) {

          let fnid = "appleuser";
          let region = "UK"  

          handleLogin({fnid, region});
        }
        else {
          console.log('raw token: ',access_token);
          // Strip useless chars from end of access_token
          var new_access_token = access_token.replace('&#x3D;','');
          var newer_access_token = new_access_token.replace('&#x3D;','');

          console.log('Token after stripping: ',newer_access_token);

          const token = atob( newer_access_token );

          console.log("Token: ", token);

          let tokenList = token.split('|||');

          console.log( tokenList );

          const fnid = atob( tokenList[1] );

          console.log('fnid', fnid);

          console.log('region prep', tokenList[0]);

          let region = '';

          if( tokenList[0] === "firstnews_uk") {
            region = "uk";
          }
          else {
            region = "international";
          }

          console.log('region ', region);

          //const fnid = queryParams.get("fnid");
          //const region = queryParams.get("region");


          window.localStorage.removeItem('gatsbyUser');
          window.localStorage.setItem("gatsbyUserCheck", fnid);
          window.localStorage.setItem("gatsbyUserRegionCheck", region);  

          handleLogin({fnid, region});
        }
      }

    }
    
  }

}