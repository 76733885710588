// Poppins typeface
import "@fontsource/poppins"; // Default Weight 400
import "@fontsource/poppins/500.css"; // Weight 500
import "@fontsource/poppins/700.css"; // Weight 700

// Bootstrap 5.1.3
import "./src/css/bootstrap.min.css"

// First News Videos CSS styles
import "./src/css/style.css"

import React from "react"
import fetch from "cross-fetch"
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from "@apollo/client"
import { relayStylePagination } from "@apollo/client/utilities"

import { isLoggedIn } from "./src/services/auth"

const user = isLoggedIn();

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        posts: relayStylePagination(["where"]),
      },
    },
  },
})

const link = new HttpLink({
  /* Set the endpoint for your GraphQL server, (same as in gatsby-config.js) */
  uri: "https://video-beta.firstnews.co.uk/graphql",
  /* Use fetch from cross-fetch to provide replacement for server environment */
  fetch
})

const client = new ApolloClient({
  link,
  cache,
})

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }
  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof window.gtag !== 'undefined') {
      gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};


export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)

